<template>
  <b-overlay
    :show="$apollo.loading || mutationLoading"
  >
    <b-tabs class="shadow p-2">
      <div class="d-flex  align-items-center justify-content-between">
        <div class="d-flex align-items-baseline ">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$router.back()"
          >
            <feather-icon
              class="mr-25"
              icon="ChevronLeftIcon"
            />
          </b-button>
          <h3 class="ml-1">
            All Resources
            <b-badge
              variant="primary"
              class="ml-50"
            >
              {{ $route.params.mname }}
            </b-badge>
          </h3>
        </div>
        <b-form-group
          class="mb-0"
          label="View as"
          label-for="btn-radios-1"
          label-size="sm"
        >
          <b-form-radio-group
            id="btn-radios-1"
            v-model="view"
            :options="[
              { text: 'Grid', value: true },
              { text: 'Table', value: false },
            ]"
            button-variant="light"
            buttons
            size="sm"
          />
        </b-form-group>
      </div>
      <hr>
      <!-- search input -->
      <div class="custom-search d-flex justify-content-start">
        <b-form-group
          class="form-inline"
          label="Search"
          label-size="sm"
        >
          <b-form-input
            v-model="searchTerm"
            class="d-inline-block mr-1"
            placeholder="Search resource"
            type="text"
          />
        </b-form-group>
      </div>
      <!-- card -->
      <section
        v-if="view"
        class="grid-view mt-1"
      >
        <b-row v-if="rows.length===0">
          <b-col>
            <b-card
              tag="article"
              style="border-radius: 10px; margin-bottom: 0; height: 100%;"
              class="ecommerce-card"
            >
              <h6 class="d-flex align-items-center justify-content-center  mb-0 text-center">
                No resources are available
              </h6>
            </b-card>
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col
            v-for="(row, i) in rows"
            :key="i"
            :title="row.title"
            md="4"
            style="margin-bottom: 10px"
          >
            <b-card
              :key="i"
              header-tag="header"
              tag="article"
              style="border-radius: 10px; margin-bottom: 0; display: flex; flex-direction: column; justify-content: space-between;"
              class="ecommerce-card"
            >
              <template #header>
                <b-card-title class="d-flex align-items-center justify-content-between  mb-0 w-100 ">
                  <div>
                    {{ row.document_name }}
                  </div>
                  <div>
                    <!--    b badge pushed inside card title-->
                    <b-badge
                      v-if="!row.is_default"
                      :variant="`${row.is_private ? 'primary' : 'secondary'}`"
                    >
                      <feather-icon :icon="`${row.is_private ? 'Lock' : 'Users'}Icon`" />
                      <span style="margin-left:4px">{{ row.is_private ? 'Private' : 'Public' }}</span>
                    </b-badge>
                    <b-badge
                      v-else
                      variant="outline-primary"
                    >
                      Default
                    </b-badge>
                  </div>
                </b-card-title>

              </template>
              <b-card-text>{{ row.description }}</b-card-text>
              <div class="d-flex align-items-baseline justify-content-between">
                <b-card-text><strong>Type of Resource: </strong></b-card-text>
                <b-badge
                  class="border"
                  variant="light-success"
                >
                  {{ getFileType(row.file) }}
                </b-badge>
              </div>
              <div class="d-flex align-items-baseline justify-content-between">
                <b-card-text><strong>Recommendation: </strong></b-card-text>
                <b-badge
                  class="border"
                  variant="light-primary"
                >
                  {{ row.recommendation }}
                </b-badge>
              </div>
              <b-card-footer class="p-0 mt-auto">
                <div class="d-flex justify-content-between mt-2">
                  <div>
                    <Promised
                      v-if="row.is_internal === true"
                      :promise="getLink(row.file)"
                    >
                      <template v-slot:pending>
                        <b-button
                          class="mr-50"
                          disabled
                          size="sm"
                          variant="outline-primary"
                        ><feather-icon icon="ClockIcon" /></b-button>
                      </template>
                      <template v-slot="data">
                        <b-button
                          :href="data"
                          class="mr-50"
                          size="sm"
                          target="_blank"
                          variant="outline-primary"
                        >View</b-button>
                      </template>
                      <template v-slot:rejected>
                        <span v-b-tooltip="`File not found`">
                          <b-button
                            class="mr-50"
                            disabled
                            size="sm"
                            variant="outline-danger"
                          ><feather-icon icon="AlertTriangleIcon" /></b-button>
                        </span>
                      </template>
                    </Promised>
                    <b-button
                      v-else
                      :href="prependHttps(row.file)"
                      size="sm"
                      target="_blank"
                      variant="outline-primary"
                      class="mr-50"
                    >View</b-button>
                  </div>
                </div>
              </b-card-footer>
            </b-card>
          </b-col>
        </b-row>
      </section>
      <!-- table -->
      <vue-good-table
        v-if="!view"
        :columns="columns"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
        :rows="rows"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <span
            v-if="props.column.field === 'file'"
            class="d-flex flex-nowrap"
          >
            <Promised
              v-if="props.row.is_internal === true"
              :promise="getLink(props.row.file)"
            >
              <template v-slot:pending>
                <b-button
                  class="mr-50"
                  disabled
                  size="sm"
                  variant="outline-primary"
                ><feather-icon icon="ClockIcon" /></b-button>
              </template>
              <template v-slot="data">
                <b-button
                  v-b-tooltip.hover
                  :href="data"
                  class="mr-50"
                  size="sm"
                  target="_blank"
                  variant="outline-primary"
                  :title="getFileType(data)"
                >View</b-button>
              </template>
              <template v-slot:rejected>
                <span v-b-tooltip="`File not found`">
                  <b-button
                    class="mr-50"
                    disabled
                    size="sm"
                    variant="outline-danger"
                  ><feather-icon icon="AlertTriangleIcon" /></b-button>
                </span>
              </template>
            </Promised>

            <b-button
              v-else
              v-b-tooltip.hover
              :href="prependHttps(props.row.file)"
              size="sm"
              target="_blank"
              variant="outline-primary"
              class="mr-50"
              :title="getFileType(props.row.file)"
            >View</b-button>
          </span>
          <span v-else-if="props.column.field === 'recommendation'">
            <b-badge
              variant="light-primary"
              class="mb-25"
            >
              {{ props.formattedRow.recommendation }}
            </b-badge>
          </span>
          <span
            v-else-if="props.column.field === 'visibility'"
            class="d-flex flex-nowrap"
          >
            <b-badge
              v-if="!props.row.is_default"
              :variant="`${props.row.is_private ? 'primary' : 'secondary'}`"
            >
              <feather-icon :icon="`${props.row.is_private ? 'Lock' : 'Users'}Icon`" />
              <span style="margin-left:4px">{{ props.row.is_private ? 'Private' : 'Public' }}</span>
            </b-badge>
            <b-badge
              v-else
              variant="outline-primary"
            >
              Default
            </b-badge>
          </span>
          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
        <template
          v-slot:column-filter="{ column, updateFilters }"
        >
          <span v-if="column.label === 'Operation'">
            <treeselect
              style="border-collapse: separate"
              :normalizer="filterNormalizer"
              :append-to-body="true"
              :options="operations"
              placeholder="Select Phase, Subphase or Activities"
              class="form-control p-0 border-0"
              @input="(val) => updateFilters(column, val)"
            />
          </span>
        </template>
        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['10','20','30']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :per-page="pageLength"
                :total-rows="props.total"
                :value="1"
                class="mt-1 mb-0"
                first-number
                last-number
                next-class="next-item"
                prev-class="prev-item"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-tabs>
  </b-overlay>
</template>
<script>

import {
  BBadge,
  BButton,
  BCard,
  BCardFooter,
  BCardText,
  BCardTitle,
  BCol,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormSelect,
  BPagination,
  BRow,
  BTabs,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { Promised } from 'vue-promised'
import { required, size } from '@core/utils/validations/validations'

export default {
  components: {
    BRow,
    BCard,
    BCol,
    BTabs,
    BFormInput,
    BFormRadioGroup,
    BFormGroup,
    BCardTitle,
    BButton,
    VueGoodTable,
    BPagination,
    BCardFooter,
    // BLink,
    BCardText,
    BFormSelect,
    Treeselect,
    BBadge,
    Promised,
  },
  data() {
    return {
      view: true,
      mutationLoading: false,
      update: false,
      searchTerm: '',
      selectedRow: 0,
      pageLength: 10,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Title',
          field: 'document_name',
          width: '25%',
        },
        {
          label: 'Description',
          field: 'description',
        },
        {
          label: 'Recommendation',
          field: 'recommendation',
          filterOptions: {
            enabled: true, // enable filter for this column
            customFilter: true, // use custom filter component
            placeholder: '-- No Filter --', // placeholder for search input
            filterDropdownItems: ['Priority', 'Interesting', 'Additional', 'Good-Read'],
          },
        },
        {
          label: 'Visibility',
          field: 'visibility',
        },
        {
          label: 'File',
          field: 'file',
          sortable: false,
        },
      ],
      rows: [],
      is_internal: true,
      is_private: true,
      file_link: null,
      file: null,
      recommendationLevel: null,
      title: null,
      description: '',
      selectedOperation: null,
      required,
      size,
    }
  },
  methods: {
    getFileType(fileName) {
      const extension = fileName.split('.')
        .pop()
        .toLowerCase()
      const fileTypes = {
        doc: 'Document',
        docx: 'Document',
        ppt: 'PPT',
        pptx: 'PPT',
        pdf: 'PDF',
        mp4: 'Video',
        avi: 'Video',
        mkv: 'Video',
        jpg: 'Photo',
        jpeg: 'Photo',
        png: 'Photo',
        gif: 'Photo',
        xls: 'Excel Sheet',
        xlsx: 'Excel Sheet',
        link: 'Link',
        com: 'Link',
        // Add more file types as needed
        txt: 'Text',
        csv: 'CSV',
        json: 'JSON',
      }
      const fileType = fileTypes[extension] || 'Unknown'

      return fileType
    },
    updateView(view) {
      this.view = view
    },

    normalizer(node) {
      return {
        id: node.id,
        label: node.title,
        children: node.programs_operationstables,
      }
    },
    filterNormalizer(node) {
      return {
        id: node.title,
        label: node.title,
        children: node.programs_operationstables,
      }
    },
    getLink(key) {
      return fetch(`https://4rf1lach53.execute-api.ap-south-1.amazonaws.com/dev/programs/files/?file_key=${key}`, {
        method: 'GET',
      }).then(response => response.json())
        .then(data => data.url)
    },
  },
  apollo: {
    rows: {
      query() {
        return gql`
        {
          programs_knowledgeresourcetable(order_by: { id: desc}, where: {knowledge_resource_id: {_eq:  ${this.$route.params.lid}}}) {
            id
            description
            document_name
            file
            recommendation
            is_internal
            is_default
            is_private
          }
        }`
      },
      update: data => data.programs_knowledgeresourcetable,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
